.TransitionScreen {
    top: 56px;
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-align: center;
}

.PauseButton {
    background-color:deepskyblue;
    color: white;
    font-size: 1.2rem;
    padding: 5px;
    width: 120px;
    border: 1px solid deepskyblue;
    cursor: pointer;
    outline: none;
    left: 50%;
    margin-top: 10px;
}

.PauseButton:hover {
    background-color: lightskyblue;
    color: white;
}

.ResumeButton {
    background-color:darksalmon;
    color: white;
    font-size: 1.2rem;
    padding: 5px;
    width: 120px;
    border: 1px solid darksalmon;
    cursor: pointer;
    outline: none;
    left: 50%;
    margin-top: 10px;
}

.ResumeButton:hover {
    background-color: lightsalmon;
    color: white;
}

.Stats {
    text-align: center;
    margin: -10px 0 15px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: lighter;
}

.LevelText {
    margin-top: 60px;
}