.StartScreen {
    top: 56px;
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    height: 800px;
    text-align: center;
}

.HeaderText {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 10px 10px;
    max-width: 690px;
    display: inline-block;

}

.SelectModeText {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.BenefitsText {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    padding: 5px 20px;
    text-align: center;
    margin-top: 30px;
    max-width: 690px;
    display: inline-block;
    line-height: 150%;
}

.BenefitsText > p,h4 {
    margin: 5px;
}

.ButtonsDiv {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0 8px;
    padding-bottom: 10px;
}

.StartButton {
    background-color: green;
    color: white;
    font-size: 1.8rem;
    padding: 5px;
    width: 180px;
    height: 45px;
    border: 1px solid green;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
}

.GooglePlayButton {
    padding: 5px;
    width: 200px;
    cursor: pointer;
    outline: none;
    margin-bottom: 10px;
}

.StartButton:hover {
    background-color: aquamarine;
    color: white;
}

.CrystalBallButton {
    background-color: mediumvioletred;
    color: white;
    font-size: 1.5rem;
    padding: 5px;
    width: 200px;
    height: 40px;
    border: 1px solid mediumvioletred;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    margin-bottom: 25px;
}

.CrystalBallButton:hover {
    background-color: palevioletred;
    color: white;
}

.VeganQuestButton {
    background-color: darkseagreen;
    color: white;
    font-size: 1.5rem;
    padding: 5px;
    width: 220px;
    height: 40px;
    border: 1px solid darkseagreen;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    margin-bottom: 25px;
}

.VeganQuestButton:hover {
    background-color: lightseagreen;
    color: white;
}

.FlagMatchButton {
    background-color: darksalmon;
    color: white;
    font-size: 1.5rem;
    padding: 5px;
    width: 280px;
    height: 40px;
    border: 1px solid darkseagreen;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    margin-bottom: 25px;
}

.FlagMatchButton:hover {
    background-color: lightsalmon;
    color: white;
}

.StartImage {
    display: inline-block;
    width: 150px;
    margin-bottom: 10px;
}

.VeganImage {
    display: inline-block;
    width: 250px;
}

.ModesImage {
    display: inline-block;
    width: 600px;
    margin-bottom: 10px;
}

.Emoji {
    display: inline-block;
    margin: 60px 0 70px 0;
    font-size: 10rem;   
}

.Emoji a {
    text-decoration: none;
}

@media only screen and (max-width: 1023px) {
    .ModesImage {
        display: inline-block;
        width: 340px;
        margin-bottom: 10px;
        margin-left: -5px;
    }
}