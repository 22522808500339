.Footer {
    position: relative;
    background-color: white;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;
}

.Footer a {
    color: darkslategrey;
    text-decoration: none;
}

.Footer :hover {
    color:darkcyan;
}

.FooterItem {
    display: inline;
    padding: 0 6px;
}

@media (prefers-color-scheme: dark) {
    .Footer {
        background-color: #202020;
    }
    .Footer a {
        color: whitesmoke;
    }

    .Footer :hover {
        color: lightskyblue;
    }
}