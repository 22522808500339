.EndScreen {
    top: 56px;
    position: absolute;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-align: center;
}

.EndScreen h3 {
    margin: 15px 0 10px 0;
    font-size: 2rem;
}

.EndScreen p {
    font-size: 1.2rem;
    font-style: italic;
    margin: 0 5px 0 5px;
}

.RestartButton {
    background-color: green;
    color: white;
    font-size: 1.6rem;
    padding: 5px;
    width: 160px;
    cursor: pointer;
    outline: none;
    left: 50%;
    margin-top: 30px;
}

.RestartButton:hover {
    background-color: aquamarine;
    color: white;
}

.Emojis {
    font-size: 7rem;
    display: inline-block;
    margin: 30px 20px 0px 20px;
}

.ShareText {
    text-align: center;
    margin-top: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
}

.ShareButtons {
    text-align: center;
    margin: 5px 0px 20px 0px;
}

.ShareButton {
    padding: 5px;
}

@media only screen and (max-width: 1023px) {

    .EndScreen h3 {
        margin: 10px 0 2px 0;
        font-size: 1.3rem;
    }
    
    .EndScreen p {
        font-size: 0.8rem;
        font-style: italic;
        margin: 0 5px 0 5px;
    }

    .Emojis {
        font-size: 4rem;
        margin: 15px 2px 0px 2px;
    }

    .RestartButton {
        font-size: 1.5rem;
        padding: 0;
        width: 120px;
        cursor: pointer;
        margin-top: 20px;
    }

    .ShareText {
        text-align: center;
        margin-top: 10px;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.2rem;
        font-weight: normal;
    }
}