.AnswerScreen {
    top: 56px;
    position: absolute;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-align: center;
    background-color: #F0F0F0;
    touch-action: none; /* Prevent pull-to-refresh */
    -webkit-user-select: none; /* Prevent select text Safari */
    -ms-user-select: none; /* Prevent select text IE 10 and IE 11 */
    user-select: none; /* Prevent select text Standard syntax */
}

.Flags {
   width: 158px;
   height: 103px;
   padding: 6px;
}

.PlayingCard {
   width: 108px;
   padding: 6px;
}

.Shapes {
   width: 120px;
   padding: 10px;
}

.Emojis {
   font-size: 6rem;
   padding: 12px;
}

.Numbers {
   font-size: 5rem;
    display: inline-block;
    padding: 0 12px 0 12px;
}

.Kids {
   font-size: 8rem;
   padding: 16px;
}

.EvenItem {
   color: purple;
}

.OddItem {
   color: navy;
}

.Question {
   font-size: 3em;
   margin: 20px 0 20px 0;
}

.AudioIcon {
   margin-left: 10px;
   vertical-align: middle;
   width: 50px;
   cursor: pointer;
}

.AnswerScreenItem {
    cursor:pointer
 }

.ItemCorrect {
   background-color: lawngreen;
   pointer-events: none;
   opacity: 0.15;
}
 
 .ItemFailed {
    background-color: red;
    pointer-events: none;
 }

 .ItemNext {
    animation: blinker 1s linear infinite;
    background-color: lawngreen;
    pointer-events: none;
 }

 @keyframes blinker {
   50% {
     opacity: 0;
   }
 }

 .ItemsLeft {
    color: red;
    bottom: 10px;
    position: fixed;
    width: inherit;
    font-size: 1.2rem;
 }

 .ItemsList {
   width: 80%;
   margin: auto;
 }


 @media only screen and (max-width: 1023px) {

   .ItemsList {
      width: 100%;
      margin: auto;
    }
   

   .Flags {
      width: 63px;
      height: 41px;
      padding: 4px;
   }

   .PlayingCard {
      width: 50px;
      padding: 4px;
   }

   .Shapes {
      width: 50px;
      padding: 10px;
   }

   .Emojis {
      font-size: 3.3rem;
      padding: 3px;
   }

   .Kids {
      display: inline-block;
      font-size: 5rem;
      padding: 4px 10px 4px 10px;
   }

   .Numbers {
      font-size: 2rem;
      padding: 8px;
   }

   .AudioIcon {
      margin-left: 10px;
      vertical-align: middle;
      width: 20px;
      cursor: pointer;
   }

   .Question {
      font-size: 1.5em;
      margin: 10px 0 10px 0;
   }
}

@media (prefers-color-scheme: dark) {
   .AnswerScreen {
     background-color: #202020;
   }

   .EvenItem {
      color: lightyellow;
   }
   
   .OddItem {
      color: lightpink;
   }

   .AudioIcon {
      filter: invert(1);
   }
 }