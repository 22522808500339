.CardSelectionScreen {
    top: 56px;
    position: absolute;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: scroll;
    text-align: center;
    background-color: #F0F0F0;
}

.PlayingCard {
  width: 120px;
  padding: 4px 2px 2px 2px;
  cursor: pointer;
}

.CardSelectionScreenItem {
    cursor:pointer;
 }

 .ItemsList {
  width: 80%;
  margin: auto;
}


@media only screen and (max-width: 1023px) {

  .ItemsList {
     width: 100%;
     margin: auto;
   }
  
  .PlayingCard {
    width: 72px;
    padding: 2px 4px 2px 4px;
    display: inline-block;
  }

  .CardSelectionScreen h2 {
    margin: 20px 0 10px 0;
  }
}

@media (prefers-color-scheme: dark) {
  .CardSelectionScreen {
    background-color: #202020;
  }
}