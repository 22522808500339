.NotFound {
    position: relative;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    display: inline-block;
    margin-top: 120px;
}

.EndImage {
    display:block;
    margin: auto;
    width: 200px;
}

.NotFound h1 {
    display: block;
    margin-top: 50px;
    text-align: center;
}

.NotFound h2 {
    display: block;
    margin: 0px;
    text-align: center;
}