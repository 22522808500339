.SlideShowScreen {
    top: 100px;
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-align: center;
}

.SlideShowFont {
    font-size: 12rem;
}

.Flags {
    border: 1px solid black;
    width: 320px;
    height: 213px;
 }

.FlagLabel {
    display: block;
    font-size: 3rem;
}

.KidsLabel {
    display: block;
    font-size: 2.5rem;
}

.PlayingCard {
    width: 275px;
 }

.PlayingCardLabel {
    display: block;
    font-size: 2.2rem;
}

.Shapes {
    width: 250px;
 }

 .ShapeLabel {
    display: block;
    font-size: 2rem;
}

.AudioIcon {
    width: 50px;
    cursor: pointer;
 }

 @media (prefers-color-scheme: dark) {
    .AudioIcon {
       filter: invert(1);
    }
}