.IntroScreen {
    top: 12%;
    position: absolute;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-align: center;
}

.IntroScreen h1 {
    margin: 50px 20px 0px 20px;
    color:teal
}

@media (prefers-color-scheme: dark) {
    .IntroScreen h1 {
      color: white;
    }
    .IntroScreen h2 {
      color: white;
    }
}