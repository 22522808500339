.EndScreen {
    top: 56px;
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-align: center;
}

.EndScreen h2 {
    margin: 15px;
}

.EndScreen h3 {
    margin: 5px 0px;
}

.ContinueButton {
    background-color: navy;
    color: white;
    font-size: 1.1rem;
    padding: 5px;
    width: 160px;
    height: 34px;
    border: 0px;
    cursor: pointer;
    outline: none;
    left: 50%;
    margin-top: 10px;
    margin-left: 5px;
}

.ContinueButton:hover {
    background-color: steelblue;
    color: white;
}

.RestartButton {
    background-color: green;
    color: white;
    font-size: 1.1rem;
    padding: 5px;
    width: 150px;
    border: 0px;
    cursor: pointer;
    outline: none;
    left: 50%;
    margin-top: 10px;
    margin-right: 5px;
    height: 34px;
}

.RestartButton:hover {
    background-color: aquamarine;
    color: white;
}

.Results {
    display: inline;
    color: green;
}

.ShareText {
    text-align: center;
    margin-top: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: normal;
}

.Stats {
    text-align: center;
    margin: 0 0 10px 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: lighter;
}

.Cup {
    text-align: center;
    font-size: 5rem;
}

.AnimalMemory {
    margin: 0;
    font-style: italic;
}

.AnimalName {
    color: navy;
    font-weight: 800;
    display: inline-block;
}

.EndImage {
    display: inline-block;
    width: 100px;
    margin-bottom: 10px;
}

.ShareButtons {
    text-align: center;
    margin: 5px 0px 20px 0px;
}

.ShareButton {
    padding: 5px;
}

@media (prefers-color-scheme: dark) {
    .AnimalName {
        color: lightskyblue
    }

    .Results {
        color: lightskyblue;
    }
  }