.TransitionScreen {
    position: absolute;
    display: block;
    width: 100%;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-align: center;
    height: 100%;
    background-color: #F0F0F0;
}

.TransitionScreen h1 {
    margin: 150px 20px 0px 20px;
    color:teal;
    font-size: 2.4em;
    font-weight: 400;
}

.Emoji {
    display: inline-block;
    margin-top: 20px;
    font-size: 10rem;   
}

@media (prefers-color-scheme: dark) {
    .TransitionScreen {
        background-color: #202020;
    }

    .TransitionScreen h1 {
        color: white;
    }
}